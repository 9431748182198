<script>
import BaseBack from '/~/components/base/back/base-back.vue'
import ActivityMobileHeader from './components/activity-mobile-header.vue'

export default {
  name: 'activity-list-mobile-layout',
  components: {
    ActivityMobileHeader,
    BaseBack,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    back: {
      type: Object,
      default: null,
    },
  },
}
</script>

<template>
  <div class="mx-auto w-full sm:w-lg sm:max-w-full sm:px-[30px]">
    <div class="hidden h-20 items-center sm:flex">
      <base-back :to="back" />
    </div>

    <activity-mobile-header :title="title" :loading="loading" :back="back" />

    <slot />
  </div>
</template>
